import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import EmailHeader from "./components/EmailHeader";
import EmailNotificationsTable from "./components/EmailNotificationsTable";
import {userAC} from "../../store/branches/user/actionCreators";
import {selectUserState} from "../../store/selectors";
import Preloader from "../Preloader";
import {LoadingStatus} from "../../store/status";
import {mapEmailNotificationsData} from "./components/Colums";


const EmailNotifications: React.FC = () => {
    const dispatch = useDispatch();


    const {
        status,
        email_notifications: {results: email_notifications = [], count: countNotifications},
    } = useSelector(selectUserState);

    const notificationsData = mapEmailNotificationsData(email_notifications);

    const handleChangeEmailNotification = (event: React.MouseEvent<HTMLElement>, id: number) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(userAC.fetchCurrentEmailNotification(id));
    };
    const [pagination, setPagination] = useState({page: 1, pageSize: 20});

    const onChangePagination = useCallback((page: number, pageSize: number) => {
        console.log('PAGE CHANGE')
        setPagination({page, pageSize});
    }, []);

    useEffect(() => {
         const data = {
            limit: pagination.pageSize,
            offset: (pagination.page - 1) * pagination.pageSize,
        }

        dispatch(userAC.fetchEmailNotifications(data));
    }, [dispatch,pagination]);

    return (
        <Preloader isLoaded={status === LoadingStatus.LOADING}>
            <React.Fragment>
                <EmailHeader/>

                <EmailNotificationsTable notificationsData={notificationsData}
                                          count={countNotifications}
                                            pageNumber={pagination.page}
                                            onPagesChange={onChangePagination}
                                         onChangeClient={handleChangeEmailNotification}
                />
            </React.Fragment>
        </Preloader>
    );
};

export default EmailNotifications;

